import self from "../img/self.png";
import mock1 from "../img/mock1.png";
import mock2 from "../img/mock2.png";
import mock3 from "../img/mock3.png";
import mock4 from "../img/mock4.png";
import mock5 from "../img/mock5.png";
import finicky from "../img/finicky2.png";

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(210, 178, 178)", "rgb(115, 86, 78)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */

export const info = {
  firstName: "Sincerely",
  lastName: "Brittany",
  initials: "SincerelyBrittany", // the example uses first and last, but feel free to use three or more if you like.
  position: "a Software Engineer/Developer",
  selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
  baseColor: colors[0],
  miniBio: [
    // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
    {
      emoji: "☕",
      text: "fueled by tea/matcha",
    },
    {
      emoji: "🌎",
      text: "based in the US",
    },
    {
      emoji: "💼",
      text: "Ex-Twitter Engineer",
    },
    {
      emoji: "📧",
      text: "sincerelybr@gmail.com",
    },
  ],
  socials: [
    {
      link: "https://dev.to/sincerelybrittany",
      icon: "fa-brands fa fa-dev",
      label: "dev.to",
    },
    {
      link: "https://sincerelybrittany.blog/",
      icon: "fa-brands fa-hashnode",
      label: "hashnode",
    },
    {
      link: "https://github.com/SincerelyBrittany",
      icon: "fa fa-github",
      label: "github",
    },
    {
      link: "https://www.linkedin.com/in/sincerelybrittany/",
      icon: "fa fa-linkedin",
      label: "linkedin",
    },
    {
      link: "https://twitter.com/SincerelyBrittt",
      icon: "fa fa-twitter",
      label: "twitter",
    },
    // {
    //   link: "https://www.youtube.com/channel/UCmV4CKFhLBZyxHyFQ6TPW6w?view_as=subscriber",
    //   icon: "fa fa-youtube",
    //   label: "youtube",
    // },
    // {
    //   link: "",
    //   icon: "fa fa-twitch",
    //   label: "twitch",
    // },
    {
      link: "https://www.buymeacoffee.com/sincerelybrittt",
      icon: "fa fa-coffee",
      label: "buymecoffee",
    },
    // Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
    // Just change the links so that they lead to your social profiles.
  ],
  bio: `I am a graduate of Flatiron School's coding bootcamp who landed an apprenticeship with Twitter, 
    with a background in finance and a proven ability to learn quickly and adapt to new environments.
    During my time at Twitter, I gained valuable experience working with cutting-edge technologies,
    including React, Node.js, and TypeScript. I also had the opportunity to collaborate with a diverse
    group of engineers and product managers, developing my skills in Agile development and delivering high-quality 
    code under tight deadlines. Now that my apprenticeship has ended, I'm excited to bring my skills and experience to a new challenge.
    I'm passionate about creating innovative solutions that solve real-world problems, and I thrive in fast-paced, dynamic environments.
    My unique background in finance gives me a strong understanding of business concepts and the importance of delivering results that drive value. I'm confident that my ability to learn quickly and work collaboratively, combined with my passion for technology, make me an ideal candidate for any team looking for a motivated and adaptable software engineer.
    I'm excited to explore new opportunities and continue growing my skills in the tech industry.
    You should hire me!`,
  skills: {
    proficientWith: [
      "javascript",
      "react",
      "git",
      "github",
      "bootstrap",
      "html5",
      "css3",
      "ruby",
    ],
    exposedTo: ["nodejs", "python"],
  },
  hobbies: [
    {
      label: "dancing",
      emoji: "💃🏽",
    },
    {
      label: "yoga",
      emoji: "🧘🏾",
    },
    {
      label: "movies",
      emoji: "🎥",
    },
    {
      label: "working out",
      emoji: "💪🏾",
    },
    {
      label: "traveling",
      emoji: "✈️",
    },
    {
      label: "music",
      emoji: "🎧",
    },
    {
      label: "wine",
      emoji: "🍷",
    },
    {
      label: "spending time with family & friends",
      emoji: "🤎",
    },
    // Same as above, change the emojis to match / relate to your hobbies or interests.
    // You can also remove the emojis if you'd like, I just think they look cute :P
  ],
  currentProjects: [
    // This is where your portfolio projects will be detailed
    {
      title: "A MERN e-commerce site template",
      //   live: "https://paytonpierce.dev",
      source: "https://github.com/SincerelyBrittany/e-commerce-mern-site",
      image: self,
      desciption: `A MERN e-commerce site template`,
      stack: [
        "React/Javascript",
        "Styled Components",
        "Material UI",
        "Redux",
        "Express",
        "MongoDB",
        "Nodemon",
        "Dotenv",
      ],
    },
    {
      title: "Finicky Felicia",
      live: "https://main--frolicking-rolypoly-232159.netlify.app/",
      image: finicky,
      desciption: `A React site for a childrens book, hosted on Netifly`,
      // source: "https://github.com/paytonjewell",
      stack: [
        "Hosted on Netifly",
        "Material UI",
        "Bootstrap",
        // "Mailchimp",
        // "Stripe",
        // "InstagramApi",
      ],
      wip: [
        "Integrate Mailchimp Subscription",
        "Add Stripe Payment For Signed Copies",
        "Add Instagram Api For Latest Post",
      ],
    },
    // {
    //   title: "Crystal Canvas",
    //   live: "https://www.crystalcanvas.com",
    //   stack: [
    //     "Hosted on AWS",
    //     "Styled Components",
    //     "Material UI",
    //     "Redux",
    //     "Express",
    //     "MongoDB",
    //     "Redux",
    //   ],
    //   // source: "https://github.com/paytonjewell",
    //   image: mock1,
    // },
  ],
  previousProjects: [
    // This is where your portfolio projects will be detailed
    {
      title: "Track Your Workout",
      //   live: "https://github.com/SincerelyBrittany/trackYourWorkout",
      source: "https://github.com/SincerelyBrittany/trackYourWorkout", // this should be a link to the **repository** of the project, where the code is hosted.
      image: mock1,
      desciption: `A single page application ("SPA") for keeping track of your workouts.`,
      stack: ["Ruby on Rails", "postgresql", "byebug", "Javascript"],
    },
    {
      title: "B-Owned",
      //   live: "https://paytonpierce.dev",
      source: "https://github.com/SincerelyBrittany/b-owned",
      image: mock2,
      desciption: `A rails application allows for individuals to search for black owned businesses.`,
      stack: ["Ruby on Rails", "byebug", "Google Maps API"],
    },
    // {
    //   title: "Music Diary",
    //   //   live: "https://paytonpierce.dev",
    //   source: "https://github.com/SincerelyBrittany/music-diary-client",
    //   // image: mock3,
    // },
  ],
};
