import React from "react";
import IconLink from "./BlogLink";
import { Box } from "@mui/material";

function BlogBlock(props) {
  const { image, live, source, title, description, stack, wip } = props;
  const sectionStyle = {
    width: "100%",

    backgroundImage: `linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(${image})`,
    // backgroundSize: "50% auto;",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className="project" style={sectionStyle}>
      <h3>{title}</h3>
      <p className="project__description">{description}</p>
      <ul className="project__stack">
        {/* {stack?.map((project, index) => (
          <li className="project__stack-item">{project}</li>
        ))} */}
      </ul>
      {/* <ul className="project__stack">
        {wip?.map((project, index) => (
          <li className="project__stack-item">{project}</li>
        ))}
      </ul> */}
      {source ? (
        <a
          href={source}
          aria-label="source code"
          className="link link--icon"
          target="_blank"
        >
          <i aria-hidden="true" className="fab fa-github"></i>
        </a>
      ) : null}

      {live ? (
        <a
          href={live}
          aria-label="live preview"
          className="link link--icon"
          target="_blank"
        >
          <i aria-hidden="true" className="fas fa-external-link-alt"></i>
        </a>
      ) : null}
    </div>
  );
}

export default BlogBlock;
