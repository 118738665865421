import React from "react";
import BlogBlock from "./BlogBlock";
import { Box, Grid } from "@mui/material";
import { info } from "../../info/Info";

export default function Portfolio() {
  let [blogs, setBlogs] = React.useState([]);

  // 3. Create out useEffect function
  React.useEffect(() => {
    fetch("https://dev.to/api/articles?username=sincerelybrittany&per_page=3")
      .then((response) => response.json())
      .then((data) => setBlogs(data));
  }, []);

  if (blogs.length === 0) return <></>;

  return (
    <section id="projects" className="section projects">
      <h2 className="section__title">Recent Blogs</h2>
      <div className="projects__grid">
        {blogs.map((blog, index) => (
          <BlogBlock
            key={index}
            image={blog.social_image}
            live={blog.url}
            // source={blog.url}
            title={blog.title}
            description={blog.description}
          />
        ))}
      </div>
      <br />
    </section>
  );
}
