import React from "react";
// import Style from "./Home.module.scss";
// import me from "../../img/self.png";
// import classNames from "classnames";
// import EmojiBullet from "./EmojiBullet";
import SocialIcon from "./SocialIcon";
import { Box } from "@mui/material";
import { info } from "../../info/Info";

export default function Contact() {
  return (
    <div>
      <section className="section contact center" id="contact">
        <h2 className="section__title">Contact</h2>
        <a href="mailto:sincerelybr@gmail.com">
          <span className="btn btn--outline">Email me</span>
        </a>
        <Box
          display={"flex"}
          gap={"1.5rem"}
          justifyContent={"center"}
          fontSize={{ xs: "2rem", md: "2.5rem" }}
        >
          {info.socials.map((social, index) => (
            <SocialIcon
              key={index}
              link={social.link}
              icon={social.icon}
              label={social.label}
            />
          ))}
        </Box>
      </section>

      <footer className="footer">
        <a
          href="https://soundcloud.com/sincerelybrittany"
          className="link footer__link"
        >
          Made with match 🍵, love ❤️, and music 🎶
        </a>
        <br />
        <br />
        <p>&copy; 2023</p>
      </footer>
    </div>
  );
}
