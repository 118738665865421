import React from "react";
import PortfolioBlock from "./PortfolioBlock";
import { Box, Grid } from "@mui/material";
import { info } from "../../info/Info";

export default function Portfolio() {
  return (
    <section id="projects" className="section projects">
      <h2 className="section__title">Currently Working On</h2>
      <div className="projects__grid">
        {info.currentProjects.map((project, index) => (
          <PortfolioBlock
            key={index}
            image={project.image}
            live={project.live}
            source={project.source}
            title={project.title}
            description={project.desciption}
            stack={project.stack}
            wip={project.wip}
          />
        ))}
      </div>
      <br />

      <h2 className="section__title">Previous Projects</h2>

      <div className="projects__grid ">
        {info.previousProjects.map((project, index) => (
          <PortfolioBlock
            key={index}
            image={project.image}
            live={project.live}
            source={project.source}
            title={project.title}
            description={project.desciption}
            stack={project.stack}
          />
        ))}
      </div>
    </section>
  );
}
