import React from "react";
// import styled from "styled-components";
import me from "../../img/self.png";
import { Box } from "@mui/material";
// import { info } from "../../info/Info";

export default function About(props) {
  return (
    <>
      <header className="header center">
        <Box
          className="logo-image"
          alt={"image of developer"}
          component={"img"}
          src={me}
          // width={{ xs: "20vh", md: "20vh" }}
          // height={{ xs: "15vh", md: "15vh" }}
          // borderRadius={"50%"}
          // p={"0.75rem"}
          // mb={{ xs: "1rem", sm: 0 }}
          // mr={{ xs: 0, md: "2rem" }}
        />
        <nav className="nav center">
          <ul className="nav__list center">
            <li className="nav__list-item">
              <a className="link link--nav" href="#projects">
                Projects
              </a>
            </li>
            <li className="nav__list-item">
              <a className="link link--nav" href="#skills">
                Skills
              </a>
            </li>
            <li className="nav__list-item">
              <a className="link link--nav" href="#contact">
                Contact
              </a>
            </li>
          </ul>

          {/* <button
            type="button"
            aria-label="toggle theme"
            class="btn btn--icon"
            onClick={() => {
              props.toggleDark();
            }}
          >
            <i aria-hidden="true" id="btn-theme" class="fas fa-moon"></i>
          </button> */}

          {/* <button
            type="button"
            aria-label="toggle navigation"
            class="btn btn--icon nav__hamburger"
          >
            <i aria-hidden="true" class="fas fa-bars"></i>
          </button> */}
        </nav>
      </header>
    </>
  );
}
