import React, { useState } from "react";

// import { GlobalStyle, LightTheme, DarkTheme } from "./styles";
import Navbar from "./components/navbar/Navbar";
import { ThemeContext, themes } from "./theme";
import "./styles.css";
import About from "./components/about/About";
import Portfolio from "./components/portfolio/Portfolio";
import Blog from "./components/blog/Blog";
import Skills from "./components/skills/Skills";
import Contact from "./components/contact/Contact";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [darkMode, setDarkMode] = React.useState(true);

  return (
    <div>
      <ThemeContext.Consumer>
        {({ changeTheme }) => (
          <Navbar
            toggleDark={() => {
              setDarkMode(!darkMode);
              changeTheme(darkMode ? themes.light : themes.dark);
            }}
          />
        )}
      </ThemeContext.Consumer>

      <About />
      <Blog />
      <Portfolio />
      <Skills />
      <Contact />
    </div>
  );
}

export default App;
