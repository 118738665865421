import React from "react";
import { info } from "../../info/Info";

export default function navbar() {
  return (
    <main>
      <div className="about center">
        <h1>
          Hi, I am <span className="about__name">Brittany.</span>
          <span className="hand">👋🏽</span>
        </h1>
        <h2 className="about__role">A Front end Developer.</h2>
        <p className="about__desc">{info.bio}</p>

        <div className="about__contact center">
          <a
            href="https://docs.google.com/document/d/1oWViP6RB62R5dNls7aMwiAHrrMQpi04XCMMuy9zQOz0/edit?usp=sharing"
            target="_blank"
          >
            <span className="btn btn--outline">Resume</span>
          </a>

          <a
            href="https://github.com/sincerelybrittany"
            aria-label="github"
            className="link link--icon"
            target="_blank"
          >
            <i aria-hidden="true" className="fab fa-github"></i>
          </a>

          <a
            href="https://linkedin.com/in/sincerelybrittany"
            aria-label="linkedin"
            className="link link--icon"
            target="_blank"
          >
            <i aria-hidden="true" className="fab fa-linkedin"></i>
          </a>
        </div>
      </div>
    </main>
  );
}
