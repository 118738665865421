import React from "react";
import { info } from "../../info/Info";
import { Box } from "@mui/material";

export default function Skills() {
  return (
    <div>
      <section className="section skills" id="skills">
        <h2 className="section__title">Proficient With </h2>
        <ul className="skills__list">
          {info.skills.proficientWith.map((proficiency, index) => (
            <li className="skills__list-item btn btn--plain" key={index}>
              {proficiency}
            </li>
          ))}
        </ul>
      </section>

      <section className="section skills" id="skills">
        <h2 className="section__title">Exposed To </h2>
        <ul className="skills__list">
          {info.skills.exposedTo.map((proficiency, index) => (
            <li className="skills__list-item btn btn--plain" key={index}>
              {proficiency}
            </li>
          ))}
        </ul>
      </section>

      <section className="section skills" id="skills">
        <h2 className="section__title"> Hobbies </h2>
        <ul className="skills__list">
          {info.hobbies.map((hobby, index) => (
            <li key={index}>
              <Box component={"span"} mr={"1rem"}>
                {hobby.emoji} {hobby.label}
              </Box>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}
